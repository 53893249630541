import React, { useState, useEffect } from "react";
import { Form, useFieldAnswer } from "@quillforms/renderer-core";
import "@quillforms/renderer-core/build-style/style.css";
import { useFormAnswers } from "@quillforms/renderer-core";
import { registerCoreBlocks } from "@quillforms/react-renderer-utils";
import "./Form.css";
import "../picture-choice";
import logo from "../assets/logos/vitex-logo.png";
import moveToLarge from "../assets/moveToLarge.png";
import Result from "./Result";
import roomChoices from "../formChoices/roomChoices";
import styleChoices from "../formChoices/styleChoices";
import lightChoices from "../formChoices/lightChoices";
import furnitureChoices from "../formChoices/furnitureChoices";
import vibeChoices from "../formChoices/vibeChoices";
import endChoices from "../formChoices/endChoices";
import paletteChoices from "../formChoices/paletteChoices";
import vacationChoices from "../formChoices/vacationChoices";
import artCoices from "../formChoices/artChoices";
import weatherChoices from "../formChoices/weatherChoices";
import bookChoices from "../formChoices/bookChoices";
import sceneryChoices from "../formChoices/sceneryChoices";
import gardenChoices from "../formChoices/gardenChoices";
import dayStartChoices from "../formChoices/dayStartChoices";
import { randomStyle, images } from "./utility/imageImports";
import ReactGA from "react-ga4";
import theme from "./utility/theme";
registerCoreBlocks();

const ShortForm = () => {
  const formAnswers = useFormAnswers();
  const [formData, setFormData] = useState(null);
  const [userChoice, setUserChoice] = useState(null);
  const [mirrorLogo, setMirrorLogo] = useState(false);
  const userRoom = useFieldAnswer("room");
  const userStyle = useFieldAnswer("style");
  const end = useFieldAnswer("end");
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);
  useEffect(() => {
    if (end && end[0] === "results") {
      ReactGA.event({
        category: "Quiz",
        action: "Completed Small Quiz",
        label: "Completed Small Quiz",
      });
      setTimeout(() => {
        setUserChoice("results");
        setFormData(formAnswers);
      }, 1000);
    }
  }, [end]);
  const handleSubmit = (
    data,
    { completeForm, setIsSubmitting, goToBlock, setSubmissionErr }
  ) => {
    setTimeout(() => {
      setIsSubmitting(false);
      completeForm();
      ReactGA.event({
        category: "Quiz",
        action: "Completed Large Quiz",
        label: "Completed Large Quiz",
      });
      setTimeout(() => {
        setUserChoice("largeForm");
        setFormData(formAnswers);
      }, 1000);
    }, 500);
  };

  return (
    <div style={{ width: "100%", height: "100vh", position: "relative" }}>
      {!formData && (
        <div className="logo-container">
          <img
            src={logo}
            alt="Logo"
            className={`fixed-logo ${mirrorLogo ? "right" : "left"}`}
            onClick={() => window.location.reload()}
          />
        </div>
      )}
      {formData && userChoice === "results" ? (
        <Result userData={formData} fordId="1" />
      ) : formData && userChoice === "largeForm" ? (
        <Result userData={formData} fordId="2" />
      ) : (
        <Form
          formId="1"
          beforeGoingNext={({
            setIsFieldValid,
            setIsPending,
            currentBlockId,
            answers,
            setFieldValidationErr,
            setIsCurrentBlockSafeToSwipe,
            goToBlock,
            goNext,
          }) => {
            if (
              currentBlockId === "style" &&
              answers[currentBlockId].value[2] !== undefined
            ) {
              setIsFieldValid(currentBlockId, false);
              setFieldValidationErr(currentBlockId, "Επέλεξε μέχρι 2 στυλ");
              setIsCurrentBlockSafeToSwipe(false);
            } else if (
              currentBlockId === "pallete" &&
              answers[currentBlockId].value[3] !== undefined
            ) {
              setIsFieldValid(currentBlockId, false);
              setFieldValidationErr(currentBlockId, "Επέλεξε μέχρι 3 παλέτες");
              setIsCurrentBlockSafeToSwipe(false);
            } else if (currentBlockId === "room") {
              console.log(answers[currentBlockId].value);
              if (answers[currentBlockId].value === undefined) {
                setIsFieldValid(currentBlockId, false);
                setFieldValidationErr(currentBlockId, "Επέλεξε ένα δωμάτιο");
                setIsCurrentBlockSafeToSwipe(false);
              } else {
                setIsPending(true);
                setTimeout(() => {
                  setIsPending(false);
                  goNext();
                }, 700);
              }
            } else if (currentBlockId === "light") {
              setIsFieldValid(currentBlockId, true);
              setFieldValidationErr(currentBlockId, "");
              setIsCurrentBlockSafeToSwipe(true);
              goNext();
              setMirrorLogo(false);
            } else if (currentBlockId === "furniture") {
              setIsFieldValid(currentBlockId, true);
              setFieldValidationErr(currentBlockId, "");
              setIsCurrentBlockSafeToSwipe(true);
              goNext();
              setMirrorLogo(false);
            } else if (currentBlockId === "end") {
              setIsPending(true);
              setTimeout(() => {
                setIsPending(false);
                goNext();
              }, 600);
            } else {
              setIsFieldValid(currentBlockId, true);
              setFieldValidationErr(currentBlockId, "");
              setIsCurrentBlockSafeToSwipe(true);
              goNext();
            }
          }}
          formObj={{
            messages: {
              "block.defaultThankYouScreen.label":
                "Ευχαριστούμε που συμπλήρωσες το ερωτηματολόγιο μας!",
              "label.hintText.enter": "↵",
              "label.errorAlert.required": "Αυτό το πεδίο είναι υποχρεωτικό",
              "label.progress.percent": "",
              "label.submitBtn": "Δες τα αποτελέσματα",
            },
            blocks: [
              {
                name: "multiple-choice",
                id: "room",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: false,
                  label: "Τι δωμάτιο θέλεις να «βάψεις»;",
                  choices: roomChoices,
                },
              },
              {
                name: "picture-choice",
                classnames: "two-choices",
                id: "style",
                attributes: {
                  label: "Ποιο στυλ σου ταιριάζει;",
                  description: "Επίλεξε μέχρι δύο στυλ",
                  required: true,
                  multiple: true,
                  choices: styleChoices(userRoom),
                  imgWidth: 200,
                  imgHeight: 200,
                  maxSelection: 2,
                  nextBtnLabel: "OK",
                },
              },
              {
                name: "multiple-choice",
                id: "light",
                attributes: {
                  classnames: "no-ok-button",

                  required: true,
                  multiple: false,
                  verticalAlign: false,
                  label: "Ποια είναι η κύρια πηγή φωτισμού στο δωμάτιο;",
                  choices: lightChoices,
                  attachment: isMobile
                    ? null
                    : {
                        type: "image",
                        url:
                          userStyle && userRoom
                            ? images[
                                `${
                                  userStyle[
                                    randomStyle[0] > userStyle.length - 1
                                      ? 0
                                      : randomStyle[0]
                                  ]
                                }/${userRoom}/forDisplay1.jpg`
                              ]
                            : undefined,
                      },
                  layout: "split-right",
                },
              },
              // {
              //   name: "multiple-choice",
              //   id: "furniture",
              //   attributes: {
              //     classnames: "no-ok-button",
              //     required: true,
              //     multiple: false,
              //     verticalAlign: false,
              //     label:
              //       "Έχεις κάποια υπάρχοντα έπιπλα ή διακοσμητικά στοιχεία;",
              //     choices: furnitureChoices,
              //     attachment: isMobile
              //       ? null
              //       : {
              //           type: "image",
              //           url:
              //             userStyle && userRoom
              //               ? images[
              //                   `${
              //                     userStyle[
              //                       randomStyle[1] > userStyle.length - 1
              //                         ? 0
              //                         : randomStyle[1]
              //                     ]
              //                   }/${userRoom}/forDisplay2.jpg`
              //                 ]
              //               : undefined,
              //         },
              //     layout: "split-left",
              //   },
              // },
              {
                name: "multiple-choice",
                id: "vibe",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: false,
                  label: "Πώς θέλεις να αισθάνεσαι στο δωμάτιο;",
                  // description: "Θέλω να νιώθω:",
                  choices: vibeChoices,
                  attachment: isMobile
                    ? null
                    : {
                        type: "image",
                        url:
                          userStyle && userRoom
                            ? images[
                                `${
                                  userStyle[
                                    randomStyle[2] > userStyle.length - 1
                                      ? 0
                                      : randomStyle[2]
                                  ]
                                }/${userRoom}/forDisplay1.jpg`
                              ]
                            : undefined,
                      },
                  layout: "split-right",
                },
              },
              {
                name: "picture-choice",
                id: "pallete",
                attributes: {
                  label: "Διάλεξε χρώματα που θα ήθελες να δεις στο σπίτι σου;",
                  description: "Επίλεξε έως 3 παλέτες χρωμάτων",
                  required: true,
                  multiple: true,
                  choices: paletteChoices,
                  imgWidth: 200,
                  imgHeight: 200,
                  maxSelection: 3,
                  nextBtnLabel: "OK",
                },
              },

              {
                name: "multiple-choice",
                id: "end",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label:
                    "Πάμε να δούμε τις αποχρώσεις που διάλεξα για εσένα ή θέλεις να κάνουμε κάποιες ακόμα ερωτήσεις για να καταλάβω καλύτερα το γούστο σου;😊",
                  choices: endChoices,
                  attachment: {
                    type: "image",
                    url: moveToLarge,
                  },
                  layout: "split-right",
                },
              },
              {
                name: "multiple-choice",
                id: "vacation",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: "Φαντάσου το ιδανικό μέρος για τις διακοπές σου:",
                  choices: vacationChoices,
                },
              },
              {
                name: "picture-choice",
                id: "art",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: true,
                  verticalAlign: false,
                  label: "Ποιο είδος τέχνης σου αρέσει περισσότερο;",
                  choices: artCoices,
                  imgWidth: 200,
                  imgHeight: 200,
                  maxSelection: 1,
                  nextBtnLabel: "OK",
                },
              },
              {
                name: "multiple-choice",
                id: "weather",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: "Ποιος είναι ο ιδανικός καιρός για εσένα;",
                  choices: weatherChoices,
                },
              },
              {
                name: "multiple-choice",
                id: "book",
                attributes: {
                  classnames: "no-ok-button",
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: "Ποιο είδος βιβλίων σου αρέσει περισσότερο;",
                  choices: bookChoices,
                },
              },
              // {
              //   name: "multiple-choice",
              //   id: "scenery",
              //   attributes: {
              //     classnames: "no-ok-button",
              //     required: true,
              //     multiple: false,
              //     verticalAlign: true,
              //     label: "Τι είδους τοπίο βρίσκεις πιο όμορφο;",
              //     choices: sceneryChoices,
              //   },
              // },
              {
                name: "picture-choice",
                id: "garden",
                attributes: {
                  required: true,
                  multiple: true,
                  classnames: "no-ok-button",
                  verticalAlign: false,
                  label: "Σε ποιο είδος κήπου θα περνούσες περισσότερο χρόνο;",
                  choices: gardenChoices,
                  imgWidth: 200,
                  imgHeight: 200,
                  maxSelection: 1,
                  nextBtnLabel: "OK",
                },
              },
              {
                name: "multiple-choice",
                id: "dayStart",
                attributes: {
                  required: true,
                  multiple: false,
                  verticalAlign: true,
                  label: "Πώς προτιμάτε να ξεκινάτε την ημέρα σας;",
                  choices: dayStartChoices,
                },
              },
            ],
            settings: {
              animationDirection: "horizontal",
              showQuestionsNumbers: false,
              showLettersOnAnswers: false,
            },
            theme: theme,
          }}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  );
};

export default ShortForm;
